export default {
  STAGE: process.env.REACT_APP_ENV || 'production',
  development: {
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
    endpoint: process.env.REACT_APP_ENDPOINT,
  },
  staging: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_CX4BKktek',
      userPoolWebClientId: '29tano7vhuf7db4gnuiaqe0dbr',
    },
    endpoint: 'https://ja-normalizer-misumi-api-stg.text-core.io',
  },
  production: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_EBJEKXkf7',
      userPoolWebClientId: '6bq2d3pu04e77um0rsoalugalh',
    },
    endpoint: 'https://ja-normalizer-misumi-api.text-core.io',
  },
  test: {
    Auth: {
      identityPoolId: '',
      region: '',
      userPoolId: '',
      userPoolWebClientId: '',
    },
    endpoint: '',
  },
}
