import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import UploadDialog from '../components/UploadDialog'

interface Props {
  caption: string
  folderName: string
}

const UploadPage: React.FC<Props> = (props: Props) => {
  const [uploading, setUploading] = useState(false)
  const [isError, setError] = useState(false)
  const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false)

  const onDropRejected = useCallback(async (test) => {
    setError(true)
  }, [])

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setShowConfirmUploadDialog(true)
  }, [])

  const { acceptedFiles, draggedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: ['.xlsx', '.xlsm'],
  })

  return (
    <>
      {uploading ? (
        <div className="dropzone uploading">
          <br />
          アップロード中...
        </div>
      ) : (
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>{props.caption}</p>
        </div>
      )}
      <UploadDialog
        close={(): void => setShowConfirmUploadDialog(false)}
        acceptedFiles={acceptedFiles}
        fileRejections={fileRejections}
        folderName={props.folderName}
        open={showConfirmUploadDialog}
      />
    </>
  )
}

export default UploadPage
