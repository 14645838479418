import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AppBar, Button, Tab, Tabs, Toolbar, Typography } from '@material-ui/core'

interface Props extends RouteComponentProps {
  signOut: () => void
}

const Navbar: React.FC<Props> = (props: Props) => {
  const selectedTab = (() => {
    switch (props.location.pathname) {
      case '/normalizer1':
        return 1
      case '/download1':
        return 2
      case '/normalizer2':
        return 3
      case '/download2':
        return 4
      default:
        return 0
    }
  })()

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">日本語磨き込み器</Typography>
        <Tabs
          style={{ flexGrow: 1, marginLeft: '1.5rem' }}
          selectionFollowsFocus
          value={selectedTab}
        >
          <Tab label="" value={0} style={{ display: 'none' }} />
          <Tab label="磨き込み処理1" onClick={() => props.history.push('/normalizer1')} value={1} />
          <Tab label="結果1" onClick={() => props.history.push('/download1')} value={2} />
          <Tab label="磨き込み処理2" onClick={() => props.history.push('/normalizer2')} value={3} />
          <Tab label="結果2" onClick={() => props.history.push('/download2')} value={4} />
        </Tabs>
        <Button color="inherit" onClick={() => props.signOut()}>
          ログアウト
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Navbar)
