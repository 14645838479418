import React, { createContext } from 'react'

import { HashRouter, Redirect, Route, RouteComponentProps } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'
import axios, { AxiosRequestConfig } from 'axios'

import config from './config'
import Navbar from './components/Navbar'
import DownloadPage from './pages/DownloadPage'
import UploadPage from './pages/UploadPage'
import './App.css'

Amplify.configure({
  Auth: config[config.STAGE].Auth,
})

export const CognitoUserContext = createContext(Amplify.Auth.user)

const isFile = (data: any): data is File =>
  data.size !== undefined && data.name !== undefined && data.type !== undefined

const isUploadToS3 = (config: AxiosRequestConfig): boolean =>
  config.method === 'put' && !!config.url && /\.s3\./.test(config.url) && isFile(config.data)

export const axiosRequestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = await Auth.currentSession()
  // s3へのPUTは Authorizationヘッダーを受け付けないらしい
  // https://stackoverflow.com/a/53031218
  if (isUploadToS3(config)) {
    delete config.headers.Authorization
    return config
  }

  if (session) {
    config.headers.Authorization = session.getIdToken().getJwtToken()
  }
  return config
}
axios.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))

const App: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const signOut = () => {
    Amplify.Auth.signOut()
    window.location.reload()
  }

  return (
    <HashRouter>
      <div className="App">
        <CognitoUserContext.Provider value={Amplify.Auth.user}>
          <Navbar signOut={signOut} />
          <Route exact path="/">
            <Redirect to="/normalizer1" />
          </Route>
          <Route exact path="/normalizer1">
            <UploadPage
              caption="日本語磨き込み処理1を実行するファイル（.xlsxまたは.xlsm）をアップロードしてください。"
              folderName="/dropzone/01_Check1/"
            />
          </Route>
          <Route exact path="/download1">
            <DownloadPage folderName="result/01_Checked1/" />
          </Route>
          <Route exact path="/normalizer2">
            <UploadPage
              caption="日本語磨き込み処理2を実行するファイル（.xlsxまたは.xlsm）をアップロードしてください。"
              folderName="/dropzone/02_Check2/"
            />
          </Route>
          <Route exact path="/download2">
            <DownloadPage folderName="result/02_Checked2/" />
          </Route>
        </CognitoUserContext.Provider>
      </div>
    </HashRouter>
  )
}

export default withAuthenticator(App)
