import React, { useCallback, useState, useEffect } from 'react'
import axios from 'axios'
import config from '../config'
import Amplify, { Auth } from 'aws-amplify'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Link from '@material-ui/core/Link'

interface Props {
  folderName: string
}

type S3File = {
  ETag: string
  Key: string
  LastModified: string
  Size: number
  StorageClass: string
}

Amplify.configure({
  Auth: config[config.STAGE].Auth,
})

const DownloadPage: React.FC<Props> = (props: Props) => {
  const [prefix, setPrefix] = useState('')
  const [downloadList, setDownloadlist] = useState<S3File[]>([])
  const [isError, setError] = useState(false)
  const [hasFileList, setHasFileList] = useState(false)

  const ResultList = (props: { results: any[] }) => {
    if (props.results.length !== 0) {
      const list = props.results
      // 更新日時の降順で並び替え
      list.sort((a, b) => new Date(b.LastModified).getTime() - new Date(a.LastModified).getTime())
      const listItems = list.map((result, index) => (
        <TableRow key={index}>
          <TableCell align={'left'} padding={'default'}>
            {result.Key.replace(prefix, '')}
          </TableCell>
          <TableCell align={'center'} padding={'default'}>
            {new Date(result.LastModified).toLocaleString('ja-JP')}
          </TableCell>
          <TableCell align={'center'} padding={'default'}>
            <Link onClick={() => getFile(result.Key.replace(prefix, ''))}>ダウンロード</Link>
          </TableCell>
        </TableRow>
      ))
      return (
        <TableBody>
          <TableRow>
            <TableCell align={'left'} padding={'default'}>
              名称
            </TableCell>
            <TableCell align={'center'} padding={'default'}>
              最終更新日時
            </TableCell>
            <TableCell align={'center'} padding={'default'}>
              操作
            </TableCell>
          </TableRow>
          {listItems}
        </TableBody>
      )
    } else {
      return (
        <tbody>
          <tr>
            <td>ファイルがありません</td>
          </tr>
        </tbody>
      )
    }
  }
  let userName: string
  Auth.currentAuthenticatedUser().then((authUser) => (userName = authUser.getUsername()))

  useEffect(() => {
    setPrefix(props.folderName + userName + '/')
    axios
      .post<{
        results: []
        exists: boolean
      }>(config[config.STAGE].endpoint + '/api/v1/generateDownloadList', {
        folder: props.folderName + userName + '/',
      })
      .then((res) => {
        setDownloadlist(res.data.results)
        setHasFileList(true)
      })
  }, [])

  const getFile = useCallback(async (fileName: string) => {
    setError(false)
    axios
      .post<{
        presignedUrl: string
        exists: boolean
        contentType: string
      }>(config[config.STAGE].endpoint + '/api/v1/generateDownloadUrl', {
        objectKey: props.folderName + userName + '/' + fileName,
      })
      .then((res) => {
        if (res.data.exists === true) {
          const linkEl = document.createElement('a')
          linkEl.href = res.data.presignedUrl
          linkEl.setAttribute('download', fileName)
          document.body.appendChild(linkEl)
          linkEl.click()
        } else {
          setError(true)
        }
      })
      .catch((error) => {
        setError(true)
      })
  }, [])

  return (
    <>
      {isError ? (
        <div className="error">
          <br />
          ダウンロードできなかったファイルがあります。
        </div>
      ) : (
        false
      )}
      <Table className="donwloads">
        {hasFileList ? (
          <ResultList results={downloadList} />
        ) : (
          <TableBody>
            <TableRow>
              <TableCell>loading....</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </>
  )
}

export default DownloadPage
